<template>
  <div class="bbr-plans--draft-plans">
    <plans-list-table
      :loading="loading"
      :plans="list"
      :meta="listMeta"
      list-for="Draft"
      @loadMore="loadMore"
      @show="showInformations"
    />
  </div>
</template>

<script>
import PlansListTable from '../components/PlansListTable'
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'DraftPlans',

  props: {
    isLoading: {
      type: Boolean,
    },
  },

  components: {
    PlansListTable,
  },

  data() {
    return {
      loading: this.isLoading,
    }
  },

  computed: {
    ...mapState({
      filter: (state) => state.plans.filter,
      list: (state) => state.plans.drafts.list,
      listMeta: (state) => state.plans.drafts.listMeta,
    }),

    currentListType() {
      return this.$route.meta.type
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    ...mapActions({
      getPlans: 'plans/getPlans',
    }),

    ...mapMutations({
      setFilter: 'plans/setFilter',
    }),

    initialize() {
      this.fetchPlans()
    },

    loadMore(nextPage) {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchPlans(nextPage)
      }
    },

    fetchPlans(page = 1) {
      if (this.loading) return

      this.$emit('fetch', page)
    },

    showInformations(planId) {
      this.$router.push({
        name: 'new.plan',
        params: { id: planId },
      })
    },
  },

  watch: {
    isLoading(value) {
      this.loading = value
    },
  },
}
</script>
